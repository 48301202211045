<template>
  <Layout>
    <div class="row">
      <div
        class="col-md-3 col-sm-6"
        v-for="campaign in data.campaigns"
        :key="campaign._id"
      >
        <b-card
          @click="adSelected(campaign)"
          tag="campaign"
          style="max-width: 20rem"
          class="mb-2 company-details"
         
        >
          <b-card-text>
            Plan Price {{ campaign.plan_price }} <br />
            No of Drivers required {{ campaign.no_of_drivers_required }} <br />
            Sticker Type {{ campaign.sticker_type }} <br />
          </b-card-text>
        </b-card>
      </div>
    </div>
  </Layout>
</template>

<script>
import { company } from "@/config/api/company";
import Layout from "../../layouts/main";
export default {
  props: ["id"],
  data() {
    return {
      data: null,
    };
  },
  components: {
    Layout,
  },
  created() {
    this.getDetails();
  },
  methods: {
    getDetails() {
      const api = company.getDetails;
      api.id = this.id;
      this.generateAPI(api)
        .then((res) => {
          this.data = res.data;
        })
        .catch((err) => {
          console.error(err.response.data);
        });
    },
    adSelected(row) {
      this.$store.dispatch("campaign/selectCampaign", row);
      this.$router.push({ name: "CampaignsDetails",params:{id:row._id} });
    },
  },
};
</script>

<style>
.company-details:hover{
  cursor: pointer;
}
</style>
